.ds-input {
  label {
    display: block;
    font-weight: 600;
    line-height: 1.5;
    font-size: 1rem;
    margin-bottom: var(--spacer-1);
  }

  &.medium {
    .ds-input__input {
      width: 40ch;
    }
  }

  &.small {
    .ds-input__input {
      width: 20ch;
    }
  }

  &.tiny {
    .ds-input__input {
      width: 5em;
      padding: 0 var(--spacer-1);
      text-align: center;
    }
  }

  .help {
    font-weight: normal;
    color: var(--gray-color-dark, #999);
  }

  &__input {
    border-radius: var(--border-radius);
    border-color: #eaeaea;
    border-style: solid;
    border-width: 2px;
    display: flex;
    align-items: center;
    transition: border-color var(--animation-speed-fast);
    padding: 0 var(--spacer-2);
    height: 55px;
    max-width: 100%;

    &.full-width {
      width: 100%;
      max-width: none;
    }

    .ds-icon {
      color: var(--gray-color-light);
      padding-right: var(--spacer-2);
    }

    &.has-error {
      border-color: var(--error-color, #ff0000);
      border-width: 2px;
    }

    input {
      display: inline-block;
      padding: 0;
      height: var(--button-height, 45px);
      font-size: 1rem;
      transition: all var(--animation-speed-fast, 0.25s) var(--animation-easing-linear);
      border: none;
      width: 100%;
      &:focus {
        outline: none;
      }

      &::placeholder {
        color: #58585a;
        opacity: 1;
      }
    }

    button {
      background: none;
      border: none;
      cursor: pointer;
    }
  }

  .ds-input__input.disabled {
    border-color: var(--gray-color-lighter);
    background-color: #f2f2f2;
  }

  &__input:focus-within {
    border-color: var(--primary-color-light);
    border-width: 2px;
  }

  .error {
    color: var(--error-color, #ff0000);
  }
}
