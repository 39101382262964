@import '../../scss/_breakpoints.scss';

.ds-featured-resource {
  --resource-height: 279px;
  height: var(--resource-height);
  width: calc(50% - 16px);
  max-width: 100%;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  align-items: flex-end;
  display: flex;

  &--full {
    width: 100%;
  }

  &--large {
    --resource-height: 590px;
    width: 100%;
  }

  h2,
  h2 a {
    font-size: var(--ds-typography-sizes-h-4-size);
    line-height: var(--ds-typography-sizes-h-4-line-height);
  }

  &__background-link,
  picture {
    display: block;
    left: 0;
    top: 0;
    position: absolute;
    width: 100%;
    height: var(--resource-height);
  }

  &::before {
    content: '';
    left: 0;
    top: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.45);
    transition: all 0.25s ease;
    pointer-events: none;
  }
  a.reverse-hover {
    &::after {
      content: url('https://media.firstbusiness.bank/image/upload/v1621977976/new-site/chevron_reverse_n2cztk.svg');
    }
  }

  &:hover {
    &::before {
      background-color: rgba(255, 255, 255, 0.75);
      mix-blend-mode: screen;
    }

    a {
      color: var(--primary-color-light);
      &.reverse-hover::after {
        content: url('https://media.firstbusiness.bank/image/upload/v1621977976/new-site/chevron_hmypjo.svg');
      }
    }
  }

  img {
    left: 0;
    top: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
  }

  &__content {
    padding: var(--spacer-3) var(--spacer-5);
    position: relative;
    z-index: 2;

    h2,
    a {
      color: #fff;
    }
  }

  &--large {
    h2,
    h2 a {
      font-size: var(--ds-typography-sizes-h-3-size);
      line-height: var(--ds-typography-sizes-h-3-line-height);
    }
    .ds-featured-resource__content {
      padding: var(--spacer-6) var(--spacer-5);
    }
  }

  @include breakpoint(sm) {
    --resource-height: 279px;
    width: 100%;
    align-items: center;
  }
}
